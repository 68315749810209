import { marked } from 'marked'
// Get reference to the original link renderer
const renderer = new marked.Renderer()
const linkRenderer = renderer.link
const imageRenderer = renderer.image

// Override link renderer method
renderer.link = (href, title, text) => {
  const html = linkRenderer.call(renderer, href, title, text)

  // Add target="_blank" to the generated HTML
  return html.replace(/^<a /, '<a target="_blank" ')
}

renderer.image = (href, title, text) => {
  const html = imageRenderer.call(renderer, href, title, text)

  // Add target="_blank" to the generated HTML
  return html.replace(/^<img /, '<img loading="lazy" ')
}

// Set options
marked.use({ renderer })

export default marked
