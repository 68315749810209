import { AccordionItem } from '@szhsin/react-accordion'
import { RefObject } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import MyMarkdown from './Markdown.tsx'

export const Hints = (props: { hintRef: RefObject<HTMLDivElement> }) => {
  const { t } = useTranslation()
  return (
    <AccordionItem header={t('hints.title')} className="h5" itemKey="accordion-hints" ref={props.hintRef}>
      <h3 className="h5">{t('hints.notesTitle')}</h3>
      <ol>
        <li>{t('hints.note1')}</li>
        <li>{t('hints.note2')}</li>
        <li>{t('hints.note3')}</li>
        <li>{t('hints.note4')}</li>
        <li>
          <MyMarkdown markdown={t('hints.note5')} />
        </li>

        <li>{t('hints.note6')}</li>
      </ol>
      <hr />
      <h3 className="h5">{t('hints.limitationsTitle')}</h3>
      <p>
        <Trans i18nKey="hints.limitationsText">
          SomeGPT weiss (fast) alles, was auf
          <a href={import.meta.env.VITE_MAIN_SITE_LINK} target={'_blank'}>
            SomeURL.ch
          </a>
          publiziert war.
        </Trans>
      </p>
      <p>{t('hints.disclaimerText')}</p>
    </AccordionItem>
  )
}
