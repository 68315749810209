import i18n from 'i18next'
import LanguageDetector, { CustomDetector, DetectorOptions } from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

// if we want to always show one language as default, no matter what the browser says
// if you want to use the browser language, just remove the CustomDetector in the detection options
export const defaultLanguage = 'de'

const myDetector: CustomDetector = {
  name: 'myDetectorsName',

  lookup(options: DetectorOptions) {
    return (
      (options.lookupLocalStorage ? localStorage.getItem(options.lookupLocalStorage) : undefined) || defaultLanguage
    )
  },
}

const languageDetector = new LanguageDetector()
languageDetector.addDetector(myDetector)

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(languageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    detection: { order: ['myDetectorsName'] },
    debug: false,
    fallbackLng: defaultLanguage,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      de: {
        translation: {
          header: {
            title: `${import.meta.env.VITE_SITE_NAME}`,
            slogan: `Ich bin ${import.meta.env.VITE_SITE_NAME} und ich weiss (fast) alles,
             was auf <1>${import.meta.env.VITE_MAIN_SITE_SHORT}</1> publiziert ist.`,
          },
          from: 'vom',
          question: {
            label: 'Deine Frage',
            placeholder: 'Frag mich etwas über die Verwaltung der acrevis.',
            buttonText: 'Frage stellen',
            microphoneLabel: 'Frage stellen (mit Mikrofon)',
            microphoneListening:
              'Mikrofon: Halten Sie den Knopf gedrückt und sprechen Sie. Schweizerdeutsch geht auch. Meistens.',
          },
          results: {
            initial: `${
              import.meta.env.VITE_SITE_NAME
            } kann jetzt auch deine Stimme erkennen, sogar auf Schweizerdeutsch. Meistens. [Klicke hier für den Knopf](/?speech=1).

Wir sind weiterhin fleissig daran, ${
              import.meta.env.VITE_SITE_NAME
            } zu verbessern, zu erweitern und die Daten regelmässig zu aktualisieren. 
                      Wir freuen uns darum über jedes Feedback. Oder gar einem Weitererzählen, falls du zufrieden bist mit ${
                        import.meta.env.VITE_SITE_NAME
                      }.
                       `,
            initialEmpty: ``,
          },
          answers: {
            oneMoment: 'Einen Moment bitte...',
            serverConnectionError: 'Verbindung zum Server abgebrochen :( Bitte bald nochmals probieren.',
            otherModelButton: 'Mit mehr Kontext versuchen (Experimentell)',
            wasItUseful: 'War die Antwort hilfreich?',
            yes: 'Ja',
            no: 'Nein',
            giveFeedback: 'Feedback geben',
            readHints: 'Hinweise und Einschränkungen lesen',
            letsTryToImprove: 'Lass uns versuchen, die Antwort zu verbessern!',
            improvePrompt: 'Frage verfeinern und nochmals probieren',
            thanksForFeedback: 'Danke für Ihr Feedback!',
            unhappy: 'Unzufrieden mit der Antwort?',
          },
          hints: {
            title: 'Hinweise und Einschränkungen',
            notesTitle: 'Hinweise',
            note1:
              'Der Bot merkt sich einmal gestellte Fragen nicht. Für Verfeinerungen muss die Frage nochmals eingegeben werden.',
            note2:
              'Es ist besser ganze Fragen zu stellen, anstatt nur Stichwörter. Manchmal hilft es auch eine Frage anders zu formulieren, um eine perfektere Antwort zu erhalten.',
            note3:
              'Oder die Jahreszahl (z.B. 2023) mitzuliefern für aktuellere Antworten. Oder generell spezifischer zu fragen.',
            note4: 'Sollte keine Antwort kommen oder die Antwort abrupt enden, am Besten nochmals probieren.',
            note5:
              'Wir arbeiten kontinuierlich daran den Service zu verbessern. Komm also später wieder vorbei, wenn du noch nicht ganz zufrieden warst. Und darum speichern wir auch deine Fragen und Antworten bei uns, damit wir davon lernen können. Wir freuen uns auch, wenn du uns Feedback gibst, das hilft uns enorm. [Datenschutzerklärung](/privacy)',
            note6:
              'Alle Angaben ohne Gewähr. Bitte überprüfe die Information auf den offiziellen Webseiten der acrevis.',
            limitationsTitle: 'Einschränkungen',
            limitationsText: `${import.meta.env.VITE_SITE_NAME} weiss (fast) alles, 
            was am ${import.meta.env.VITE_DATE_DE} auf <1>${import.meta.env.VITE_MAIN_SITE_SHORT}</1> publiziert war.`,
            disclaimerText: 'Diese Website ist nicht mit der acrevis assoziiert.',
          },
          contact: {
            title: 'Kontakt und Feedback',
            emailLabel: 'Deine E-Mail (optional):',
            messageLabel: 'Deine Nachricht:',
            submitButton: 'Abschicken',
            feedbackReceived: 'Feedback erhalten, danke!',
            feedbackReceivedError: 'Da ist was schief gelaufen, bitte kontaktiere uns auf anderen Wegen.',
            emailText: 'Oder senden Sie eine Mail an chregu@liip.ch',
            moreContact: 'Mehr Kontaktmöglichkeiten auf',
          },
          footer: {
            credits: 'Powered by <1>Liip</1> — <3>Blog Post über das Projekt</3>',
            disclaimer: `${import.meta.env.VITE_SITE_NAME} ist nicht mit der acrevis assoziiert.`,
            selectLanguage: 'Sprache auswählen',
          },
        },
      },
    },
  })

export default i18n
