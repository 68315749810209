import { Dispatch, SetStateAction, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Microphone } from '../Microphone.tsx'
import GlobalContext from './GlobalContext.tsx'
const mediaType =
  window.MediaRecorder &&
  (MediaRecorder.isTypeSupported('audio/mp4')
    ? 'audio/mp4'
    : MediaRecorder.isTypeSupported('audio/webm')
      ? 'audio/webm'
      : MediaRecorder.isTypeSupported('audio/wav')
        ? 'audio/wav'
        : undefined)
console.log('medaType:', mediaType)

export class AudioRecorder {
  private mediaRecorder: MediaRecorder | null = null
  private audioChunks: Blob[] = []
  private globalStream: Promise<MediaStream> | undefined
  private started = false
  private totalSize = 0
  private timeout: number | undefined
  constructor(private readonly callback: (audioBlob: Blob | null) => Promise<void>) {
    this.globalStream =
      navigator.mediaDevices &&
      window.MediaRecorder &&
      navigator.mediaDevices.getUserMedia({ audio: true, video: false })
  }

  public async start(): Promise<void> {
    if (this.globalStream) {
      // needed in case stop() happens before the steam was initialized
      this.started = true

      const stream = await this.globalStream
      try {
        this.mediaRecorder = new MediaRecorder(stream, { mimeType: mediaType })
        console.log('new mediaRecorder initialized')
        this.mediaRecorder.ondataavailable = event => {
          this.totalSize += event.data.size
          this.audioChunks.push(event.data)
          if (this.totalSize > 250_000) {
            console.log('Reached max size, stopping', this.totalSize)
            this.stop()
          }
        }

        this.mediaRecorder.onerror = error => {
          console.error('Error: ', error)
        }
        this.mediaRecorder.onstop = async () => {
          if (this.timeout) {
            window.clearTimeout(this.timeout)
          }
          const audioBlob = new Blob(this.audioChunks, { type: mediaType })
          if (audioBlob.size > 10_000 && audioBlob.size < 300_000) {
            await this.callback(audioBlob)
          } else {
            console.error('Audio too big or too small: ', audioBlob.size)
            await this.callback(null)
          }
          this.stopStream(stream)
        }

        this.audioChunks = []
        this.totalSize = 0

        if (this.started) {
          this.mediaRecorder.start(1000)
          if (this.timeout) {
            window.clearTimeout(this.timeout)
          }
          // stop after 20 seconds
          this.timeout = window.setTimeout(() => {
            this.stop()
            this.stopStream(stream)
          }, 20_000)
        } else {
          this.stopStream(stream)
        }
      } catch (error) {
        console.log('ERROR', error)
      }
    }
  }

  private stopStream(stream: MediaStream) {
    stream.getTracks().forEach(track => track.stop())
  }

  public stop(): void {
    if (this.mediaRecorder) {
      this.mediaRecorder.stop()
    }
    this.started = false
  }
}

export const SpeechButton = (props: { setInputValue: Dispatch<SetStateAction<string>> }) => {
  const { t } = useTranslation()
  const { mainLanguage, host, org } = useContext(GlobalContext)

  //const [recording, setRecording] = useState<boolean>(false)
  const [mediaRecorder, setMediaRecorder] = useState<AudioRecorder | null>(null)

  const startRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop()
    }
    const mr = new AudioRecorder(sendDataToServer)
    setMediaRecorder(mr)
    console.log('startRecording')
    mr.start()
  }

  const sendDataToServer = async (audioBlob: Blob | null): Promise<void> => {
    if (!audioBlob) {
      console.log("audioBlob doesn't exist")
      return
    }
    const formData = new FormData()
    let ext = 'wav'
    if (audioBlob.type === 'audio/mp4') {
      ext = 'mp4'
    } else if (audioBlob.type === 'audio/webm') {
      ext = 'webm'
    }
    formData.append('file', audioBlob, `audio.${ext}`)
    formData.append('lang', mainLanguage)
    if (mediaType) {
      formData.append('type', mediaType)
    }

    try {
      const response = await fetch(host + `/${org}/speech?key=X9hL4Gp5W2D7eRtF&lang=${mainLanguage}`, {
        method: 'POST',
        body: formData,
      })

      if (!response.ok) {
        throw new Error(`Server responded with ${response.status}: ${response.statusText}`)
      }

      const responseData = await response.text()
      props.setInputValue(responseData)
      console.log('Server Response:', responseData)
    } catch (error) {
      console.error('Error uploading audio:', error)
    }
  }

  const stopRecording = () => {
    console.log('stopRecording')
    if (mediaRecorder) {
      mediaRecorder.stop()
      //  setRecording(false)
    }
  }
  if (!mediaType) {
    return null
  }

  return (
    <>
      <button
        type="button"
        className="button--microphone button"
        onMouseDown={() => {
          console.log('onmousedown')
          startRecording()
        }}
        onMouseUp={() => {
          console.log('onmouseup')
          stopRecording()
        }}
        onTouchStart={() => {
          console.log('touchstart')
          startRecording()
        }}
        onTouchEnd={() => {
          console.log('touchend')
          stopRecording()
        }}
      >
        <span className="sr-only">{t('question.microphoneLabel')}</span>
        <Microphone />
        <div className="button__hint">{t('question.microphoneListening')}</div>
      </button>
    </>
  )
}
